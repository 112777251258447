import type { AS_SearchBox } from '../../../types'

const standard: AS_SearchBox.AnalysisFuncs = {
  // TODO
  'click_your_sa_event_name.comp_search-box': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
